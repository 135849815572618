<template>
  <v-container class="share-screen">
    <v-row>
      <v-col class="pb-0 py-5" cols="10">
      </v-col>
      <v-col class="pb-0" cols="2">
        <img @click="() => { close(); }" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
      </v-col>
    </v-row>
    <div style="margin-top: 50%" v-if="link">
      <p class="main-title text-center pl-6 pr-8">
        Para efetuar o compartilhamento faça o acesso pelo botão
      </p>

      <generic-btn
        style="width: 90%; margin-top: 7%"
        class="ml-5"
        :btn-props="{ }"
        :on-click="() => nativeShare()"
      >
        Compartilhar anexos
      </generic-btn>
    </div>
    <div v-if="!link">
      <p class="main-title pl-2 pr-8">
        Clique para selecionar os exames ou
      </p>

      <v-radio-group
        class="pl-2 pt-6"
        column
        :value="options"
      >
        <v-radio
          color="tertiary"
          label="Todos os exames"
          :value="allExams"
          @click="() => options === allExams ? options = null : options = allExams"
        />
        <v-radio
          color="tertiary"
          label="Deste mês"
          :value="thisMonth"
          @click="() => options === thisMonth ? options = null : options = thisMonth"
        />
        <v-radio
          color="tertiary"
          label="Do último mês"
          :value="lateMonth"
          @click="() => options === lateMonth ? options = null : options = lateMonth"
        />
      </v-radio-group>

      <v-list v-if="exams.length > 0" three-line>
        <v-divider/>
        <div
          v-for="exam in shareExams"
          :key="exam.id"
        >
          <v-list-item @click="() => exam.selected = !exam.selected" :style="exam.selected ? 'background: #F8F8FF' : ''">
            <v-list-item-content >
              <v-list-item-title
                style="font-size: 18px"
                class="main-title text-truncate"
              >
                {{ (exam.examSpeciality && exam.examSpeciality.description) || 'Nome do exame não informado' }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-chip x-small color="#fdddef" style="color: var(--v-tertiary); font-weight: 900">
                  exame {{ getExamTypeText(exam.examType && exam.examType.code) }}
                </v-chip>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                  class="text-truncate pt-2"
                  style="font-size: 16px; color: #858585;"
              >
                Data do exame {{ moment(exam.scheduledDate).format('DD/MM/YYYY') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <img
                  v-if="exam.selected"
                  src="/img/appointments/check-icon.svg"
                  style="width: 30px; height: 30px;"
                  class="mt-3"
              />
            </v-list-item-action>
          </v-list-item>
          <v-divider/>
        </div>
      </v-list>

      <p v-else class="main-title text-center pl-6 pr-8">
        Você não possui exames com anexos
      </p>
    </div>

    <v-card v-if="!link" elevation="16" class="step-btn">
      <generic-btn
        style="width: 90%; margin-top: 7%"
        class="ml-5"
        :btn-props="{}"
        :on-click="() => shareExam()"
      >
        Compartilhar
      </generic-btn>
    </v-card>

    <shareWarn :acc-term="accTerms" :btn-action="toggleDialog" />
  </v-container>
</template>

<script>
import moment from 'moment';
import shareWarn from './shareWarn';
import ExamEventService from "@/services/healthcare/ExamEventService";
import { examTypes } from "../../common/constants";
import { mapMutations } from 'vuex';
import {Capacitor} from "@capacitor/core";
import {Share} from "@capacitor/share";

export default {
  name: 'exameShare',
  components: {
    shareWarn
  },
  props: {
    close: {
      type: Function,
      required: true
    },
    exams: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      moment,
      shareExams: [],
      options: null,
      allExams: 'allExams',
      thisMonth: 'thisMonth',
      lateMonth: 'lateMonth',
      link: null,
      accTerms: true
    }
  },
  watch: {
    options() {
      let currentMonth = moment();
      let lastMonth = moment().subtract(1, 'month')

      if(this.options === this.allExams) {
        this.shareExams.map((exam) => {
          exam.selected = true
        })
      }

      if(this.options === null) {
        this.shareExams.map((exam) => {
          exam.selected = false
        })
      }

      if(this.options === this.thisMonth) {
        this.shareExams.map((exam) => {
          if(moment(exam.scheduledDate).isSame(currentMonth, 'month'))
            exam.selected = true
        })
      }

      if(this.options === this.lateMonth) {
        this.shareExams.map((exam) => {
          if(moment(exam.scheduledDate).isSame(lastMonth, 'month'))
            exam.selected = true
        })
      }
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    getExamTypeText(type) {
      if(type === examTypes.self)
        return 'Iniciativa própria'

      if(type === examTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === examTypes.refer)
        return 'Indicação médica'
    },
    toggleDialog() {
      this.accTerms = !this.accTerms;
    },
    async nativeShare() {
      if(Capacitor.isNativePlatform()) {
        this.loading(true);

        Share.share({
          title: 'Exame - Compartilhar Anexo',
          text: this.link.text,
          url: this.link.link,
          dialogTitle: 'Eu Protegido',
        });

        setTimeout(() => {
          this.loading(false);
        }, 1500)

      } else {

        if (navigator.share) {
          try {
            let share = {
              url: this.link.link,
              title: 'Eu Protegido',
              text: this.link.text,
            }
            await navigator.share(share);
          } catch (error) {
            this.showmsg({
              text: "Não é possivel compartilhar por este dipositivo.",
              type: "red"
            });
          }
        }else {
          this.showmsg({
            text: "Não é possivel compartilhar por este dipositivo.",
            type: "red"
          });
        }
      }
    },
    shareExam() {
      this.loading(true);

      let examsId = [];

      this.shareExams.map((exam) => {
        if(exam.selected)
          examsId.push(`${exam.id}`)
      });

      if(examsId.length > 0) {
        this._examEventService.SharingExamAttachment(examsId)
          .then(({ data }) => {
            this.link = data
          })
          .catch(() =>
            this.showmsg({
              text: "Erro ao compartilhar!",
              type: "error",
            })
          )
          .finally(() => this.loading(false));
      }else {
        this.showmsg({
          text: "Selecione pelo menos um exame!",
          type: "error",
        })
        this.loading(false)
      }
    }
  },
  beforeMount() {
    let newExams = [];

    this.exams.map((exam) => {
      newExams.push({ ...exam, selected: false })
    });

    this.shareExams = newExams;
    window.scrollTo(0,0)
  },
  beforeCreate() {
    this._examEventService = new ExamEventService();
  },
}
</script>

<style scoped>
.share-screen {
  height: 100%;
  background: white;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.step-btn {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 150px;
  position: fixed;
  top: 86%;
  left: 0;
  z-index: 10;
}

</style>
