<template>
  <v-container
    :style="{ height: '100%', display: 'block', width: '100vw', background: !register ? 'rgba(255, 255, 255, .5)' : 'white'}"
    class="pa-6 pt-2 fluid background-glass-effect"
  >
    <backArrow v-if="!register" :route="'root'" />
    <v-layout class="mt-1" v-if="!register && !registerSuccess" align-end justify-end>
      <img  @click="$router.push('/principais-duvidas')" src="/img/nick/question-nick.svg" style="width: 55px"/>
    </v-layout>
    <v-layout v-if="!register && !registerSuccess" align-start justify-start>
      <v-row>
        <v-col cols="6" class="pb-0">
          <!--         <v-select-->
          <!--           :items="['aa', 'ss']"-->
          <!--           rounded-->
          <!--           background-color="#e4e7f8"-->
          <!--           append-icon="fas fa-chevron-down"-->
          <!--           height="35"-->
          <!--         />-->
        </v-col>
      </v-row>
    </v-layout>
    <Totalizer
      v-if="!register && !registerSuccess"
      :title="'Exames'"
      class="mb-2 pt-11"
      :totals="totals"
      :title-icon="titleIcon"
      color="#FE7B93"
      :isExam="true"
      :height="80"
    />
    <v-row class="pt-0 mt-0 pb-1 mb-1 px-4">
      <v-col cols="12" class="pt-0 mt-0 pb-1 mb-1">
        <v-autocomplete
            v-if="showComboBeneficiaries"
            label="Selecione o beneficiário"
            :items="beneficiaries"
            v-model="user"
            return-object
            item-value="cpf"
            item-text="name"
            color="primary"
            :disabled="busy"
            @change="onClickComboBeneficiary()">
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-layout v-if="!register && !registerSuccess" justify-center align-center>
      <v-card
        style="background: rgba(231, 234, 254, .6);"
        class="option-list-card list-card mb-6"
      >
        <v-row justify="center">
          <v-col class="pl-6 pr-0 pt-5" cols="7">
            <div
              :class="!activeFilter ? 'list-card pt-4' : 'pb-1 pt-4'"
              :style="!activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center filter-text generic-text mb-0"
                @click="onClickTab()"
              >
                Próximos e Atrasados
              </p>
            </div>
          </v-col>
          <v-col class="pl-0 pr-7 pt-5" cols="5">
            <div
              :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center history-margin-adjust filter-text generic-text mb-0"
                @click="onClickTab()"
              >
                Histórico
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
    <v-layout v-if="!register && !registerSuccess">
      <ProgressiveLoader class="container" :disabled="busy" @reachedEnd="getExams">
        <v-row v-if="!activeFilter" justify="center">
          <v-col v-if="lateExams.length > 0" cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Atrasados</p>
          </v-col>
          <v-col v-for="exam in lateExams" :key="'t_'+exam.id" cols="12">
            <examCard v-if="isWaiting(exam)"
              color="tertiary"
              :exam="exam"
              :action="() => {
                setStatusToShowCombo(false);
                currentExam = exam;
                realizeExam = true;
                register = true;
              }"
              :detail="() => {
                setStatusToShowCombo(false);
                detailExam = true;
                realizeExam = true;
              }"
              :toggle-attach="() => { showAttach = true; currentExam = exam; }"
            />
            <examCard v-else-if="isScheduled(exam)"
                color="green"
                :exam="exam"
                btn-title="Comparecimento"
                scheduled-type
                :action="() => {
                  setStatusToShowCombo(false);
                  currentExam = exam;
                  realizeExam = true;
                  register = true;
              }"
                :detail="() => {
                  setStatusToShowCombo(false);
                  detailExam = true;
                  realizeExam = true;

              }"
                :toggle-attach="() => { showAttach = true; currentExam = exam; }"
            />
          </v-col>
          <v-col cols="12" v-if="nextExams.length > 0 || waitingExams.length > 0">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Próximos</p>
          </v-col>
          <v-col v-for="exam in nextExams" :key="'a_'+exam.id" cols="12">
            <examCard v-if="isScheduled(exam)"
              color="green"
              :exam="exam"
              btn-title="Comparecimento"
              scheduled-type
              :action="() => {
                setStatusToShowCombo(false);
                currentExam = exam;
                realizeExam = true;
                register = true;
              }"
              :detail="() => {
                setStatusToShowCombo(false);
                detailExam = true;
                realizeExam = true;

              }"
              :toggle-attach="() => { showAttach = true; currentExam = exam; }"
            />
            <examCard v-else-if="isWaiting(exam)"
                color="cyan"
                :exam="exam"
                :action="() => {
                  setStatusToShowCombo(false);
                  currentExam = exam;
                  realizeExam = false;
                  register = true;
                }"
                :detail="() => {
                  setStatusToShowCombo(false);
                  detailExam = true;
                  realizeExam = true;
                }"
                :toggle-attach="() => { showAttach = true; currentExam = exam; }"
            />
          </v-col>
        </v-row>
        <v-row  v-if="activeFilter && fullfilledExams.length > 0">
          <v-col cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Realizados</p>
          </v-col>
          <v-col class="col-card" v-for="exam in fullfilledExams" :key="'r_'+exam.id" cols="12">
            <examCard
              :exam="exam"
              done-type
              :action="() => {
                setStatusToShowCombo(false);
                currentExam = exam;
                realizeExam = true;
                register = true;
              }"
              :detail="() => {
                setStatusToShowCombo(false);
                detailExam = true;
                realizeExam = true;
              }"
              :toggle-attach="() => { showAttach = true; currentExam = exam; }"
            />
          </v-col>
        </v-row>
        <v-row  v-if="activeFilter && unrealizedExams.length > 0" class="pt-4">
          <v-col cols="12">
            <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Não Realizados</p>
          </v-col>
          <v-col class="col-card" v-for="exam in unrealizedExams" :key="'r_'+exam.id" cols="12">
            <examCard
                :exam="exam"
                unrealized-type
                :detail="() => {
                  setStatusToShowCombo(false);
                  detailExam = true;
                  realizeExam = false;
                }"
                :toggle-attach="() => { showAttach = false; currentExam = exam; }"
            />
          </v-col>
        </v-row>
      </ProgressiveLoader>
    </v-layout>

    <examRegister
      :success="() => {setStatusToShowCombo(false); registerSuccess = true; }"
      :close="() => { register = false; detailExam = false; showAttach= false; currentExam = null; realizeExam = false; onCloseFromPushNotification(); setStatusToShowCombo(true);}"
      v-if="register"
      :exam="currentExam"
      :clear-exam="() => currentExam = null"
      :realize-type="realizeExam"
      :detail-only="detailExam"
      :share-attach="() => showAttach = true"
      :userId="user.userId"
      :userCpf="user.cpf"
      :dependentId="user.dependentId"
      :beneficiaries="beneficiaries"
      v-on:updateExamTotalizers="updateExamTotalizers"
      :share-attach-on="showAttach"
      :refresh-exams="getExams"
      v-on:verifyIsRealized="verifyIsRealized"
    />

    <examShare
      :close="() => shareExam = false"
      :exams="shareExams"
      v-if="shareExam"
    />

    <exameAttachments
      :exam="currentExam"
      :close="() => showAttach = false"
      v-if="showAttach"
    />

    <v-container v-if="registerSuccess">
      <v-card class="success-card" flat>
        <v-container class="pa-7">
          <img src="/img/icons/exame/side-view-microscope-lab-bounded.svg" class="success-img">
          <p style="font-size: 36px" class="mt-16 pt-12 main-title">
            Exame Registrado
          </p>

          <p v-if="!isRealized" style="font-size: 24px" class="pt-4 main-text">
            Não esqueça de informar se foi ao exame
          </p>
          <generic-btn
            style="width: 85%; bottom: 10%; position: absolute"
            :btn-props="{}"
            :on-click="() => {refreshRegister(); registerSuccess = false; setStatusToShowCombo(true);}"
          >
            Ok
          </generic-btn>
        </v-container>
      </v-card>
    </v-container>

    <v-card
      style="position: fixed"
      v-if="!register && !registerSuccess"
      color="#FE7B93"
      class="plus-icon"
      elevation="10"
      @click="() => { register = true; currentExam = null; realizeExam = false; detailExam = false; setStatusToShowCombo(false); }"
    >
      <v-layout justify-center align-center>
        <img src="/img/icons/common/plus-icon.svg" class="mt-4">
      </v-layout>
    </v-card>

    <v-card
      style="position: fixed"
      v-if="!register && !registerSuccess"
      color="primary"
      class="share-icon"
      elevation="10"
      @click="() => { shareExam = true }"
    >
      <v-layout justify-center align-center>
        <img src="/img/icons/common/share-icon.svg" style="margin-right: 3px" class="mt-3">
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import Totalizer from '@/components/generic-totalizer';
import examCard from "../../components/exame/exameCard";
import examRegister from "../../components/exame/exameRegister";
import examShare from "../../components/exame/exameShare";
import exameAttachments from "../../components/exame/exameAttachments";
import { mapMutations } from 'vuex';
import moment from 'moment';
import ExamEventService from "@/services/healthcare/ExamEventService";
import {examStatus, examTypes} from '../../common/constants';
import ProgressiveLoader from "../../components/progressive-loader/index";

export default {
  name: 'Exam',
  components: {
    backArrow,
    Totalizer,
    examCard,
    examRegister,
    examShare,
    exameAttachments,
    ProgressiveLoader
  },
  data() {
    return {
      busy: true,
      titleIcon: '/img/icons/exame/side-view-microscope-lab.svg',
      totals: [],
      activeFilter: false,
      lateExams: [],
      fullfilledExams: [],
      unrealizedExams: [],
      nextExams: [],
      waitingExams: [],
      shareExams: [],
      moment,
      register: false,
      registerSuccess: false,
      currentExam: null,
      realizeExam: false,
      detailExam: false,
      shareExam: false,
      showAttach: false,
      isRealized: false,
      orderLoadingTypes: 1,
      orderLoadingTypesRealized: 1,
      pageLoad: -1,
      pageLoadRealizeds: -1,
      selectedLoadingType: '',
      selectedLoadingTypeRealized: '',
      user: {
        name: null,
        cpf: null,
        userId: null,
        dependentId: null,
      },
      beneficiaries: [],
      showComboBeneficiaries: true,
    }
  },
  watch: {
    register(newValue) {
      if(!newValue) {
        this.refreshRegister();
      }
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    async refreshRegister() {
      this.nextExams = [];
      this.lateExams = [];
      this.fullfilledExams = [];
      this.unrealizedExams = [];
      this.waitingExams = [];
      this.orderLoadingTypes = 1;
      this.orderLoadingTypesRealized = 1;
      this.pageLoad = -1;
      this.pageLoadRealizeds = -1;
      this.selectedLoadingType = '';
      this.selectedLoadingTypeRealized = '';
      await this.getExams();
      this.updateExamTotalizers();
    },
    isWaiting(exam){
      return exam.status === examStatus.waiting
    },
    isScheduled(exam){
      return exam.status === examStatus.scheduled
    },
    async onClickTab(){
      this.activeFilter = !this.activeFilter
      this.clearData();
      await this.getExams()
    },
    updateExamTotalizers(){
      this.getTotals();
    },
    verifyIsRealized(){
      this.isRealized = true
    },
    getExamTypeText(type) {
      if(type === examTypes.self)
        return 'Iniciativa própria'

      if(type === examTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === examTypes.refer)
        return 'Indicação médica'
    },
    async getExams() {
      this.loading(true)
      this.busy = true

      if(!this.activeFilter) {

        this.pageLoad++;
        if (this.orderLoadingTypes === 1) {
          this.selectedLoadingType = 'ATRASADO_AGUARDANDO'
        } else if (this.orderLoadingTypes === 2) {
          this.selectedLoadingType = 'ATRASADO_AGENDADO'
        } else if (this.orderLoadingTypes === 3) {
          this.selectedLoadingType = 'PROXIMO_AGUARDANDO'
        } else {
          this.selectedLoadingType = 'PROXIMO_AGENDADO'
        }

        let params = {
          eventStatus: this.selectedLoadingType,
          page: this.pageLoad,
          size: 4,
          sortOrder: 'ASC'
        }

        if (this.orderLoadingTypes > 4) {
          this.loading(false)
          this.busy = false
          return;
        }

        if (this.user.dependentId) {
          this.user.userId = null;
        }

        await this._examEventService.FindAllByUserAndFilter(params, this.user.userId, this.user.dependentId)
            .then(async response => {
              if (response.data && response.data.content.length === 0) {
                this.pageLoad = -1
                this.orderLoadingTypes++
                await this.getExams();
              } else {

                if (this.selectedLoadingType === 'ATRASADO_AGUARDANDO') {

                  if (this.lateExams && this.lateExams.length > 0) {
                    this.lateExams = this.lateExams.concat(response.data.content)
                  } else {
                    this.lateExams = response.data.content
                  }

                } else if (this.selectedLoadingType === 'ATRASADO_AGENDADO') {

                  if (this.lateExams && this.lateExams.length > 0) {
                    this.lateExams = this.lateExams.concat(response.data.content)
                  } else {
                    this.lateExams = response.data.content
                  }

                } else if (this.selectedLoadingType === 'PROXIMO_AGUARDANDO') {

                  if (this.nextExams && this.nextExams.length > 0) {
                    this.nextExams = this.nextExams.concat(response.data.content)
                  } else {
                    this.nextExams = response.data.content
                  }

                } else {

                  if (this.nextExams && this.nextExams.length > 0) {
                    this.nextExams = this.nextExams.concat(response.data.content)
                  } else {
                    this.nextExams = response.data.content
                  }
                }

                if (response.data && response.data.content.length === 1) {
                  await this.getExams();
                }
              }
            })
            .catch(() => {
              this.showmsg({
                text: "Erro ao carregar os exames atrasados e/ou próximos.",
                type: "error",
              })
            })
            .finally(() => {
              this.loading(false)
              this.busy = false
            });

      }else{

        this.pageLoadRealizeds++;
        if (this.orderLoadingTypesRealized === 1) {
          this.selectedLoadingTypeRealized = 'REALIZADO'
        } else {
          this.selectedLoadingTypeRealized = 'NAO_REALIZADO'
        }

        let params = {
          eventStatus: this.selectedLoadingTypeRealized,
          page: this.pageLoadRealizeds,
          size: 4,
          sortOrder: 'DESC'
        }

        if(this.orderLoadingTypesRealized > 2) {
          this.loading(false)
          this.busy = false
          return;
        }

        if (this.user.dependentId) {
          this.user.userId = null;
        }

        await this._examEventService.FindAllByUserAndFilter(params, this.user.userId, this.user.dependentId)
            .then(async response => {
              if (response.data && response.data.content.length == 0) {
                this.pageLoadRealizeds = -1
                this.orderLoadingTypesRealized++
                await this.getExams();
              } else {

                if (this.selectedLoadingTypeRealized === 'REALIZADO') {
                  this.shareExams = this.shareExams.concat(response.data.content)

                  if (this.fullfilledExams && this.fullfilledExams.length > 0) {
                    this.fullfilledExams = this.fullfilledExams.concat(response.data.content)
                  } else {
                    this.fullfilledExams = response.data.content
                  }

                } else {

                  if (this.unrealizedExams && this.unrealizedExams.length > 0) {
                    this.unrealizedExams = this.unrealizedExams.concat(response.data.content)
                  } else {
                    this.unrealizedExams = response.data.content
                  }
                }

                if (response.data && response.data.content.length === 1) {
                  await this.getExams();
                }
              }
            })
            .catch(() => {
              this.showmsg({
                text: "Erro ao carregar os exames realizados.",
                type: "error",
              })
            })
            .finally(() => {
              this.loading(false)
              this.busy = false
            });
      }

      if(this.shareExams.length){
        this.shareExams = this.shareExams.filter((exam) => {
          if(exam.attachments.length > 0) return true
        })
      }
    },
    getTotals() {
      this.loading(true);

      if (this.user.dependentId) {
        this.user.userId = null;
      }

      this._examEventService.countExamTotals(this.user.userId, this.user.dependentId)
        .then(({ data }) => {
          this.totals = [{
            label: 'Sugestões',
            value: data.totalSuggestionLastYear
          }, {
            label: 'Próximos',
            value: data.totalSuggestionAndScheduledLastYear
          }, {
            label: 'Histórico',
            value: data.totalRealizeds
          },]
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar totalizadores!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    async detailExamByPushNotification(){
      this.loading(true);
      if(this.$route.params &&  this.$route.params.id){
        const id = this.$route.params.id;
        await this._examEventService.findById(id)
          .then(response => {
            if(response.data) {
              this.register = true;
              this.realizeExam = true;
              this.currentExam = response.data;
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar o exame notificado.",
              type: "error",
            })
          })
          .finally(() => {
            this.loading(false);
          })
      }
    },
    onCloseFromPushNotification(){
      if(this.$route.params &&  this.$route.params.id){
        this.clearData();
      }
    },
    clearData(){
      this.lateExams = [];
      this.fullfilledExams = [];
      this.unrealizedExams = [];
      this.nextExams = [];
      this.waitingExams = [];
      this.shareExams = [];
      this.register = false;
      this.registerSuccess = false;
      this.currentExam = null;
      this.realizeExam = false;
      this.detailExam = false;
      this.shareExam = false;
      this.showAttach = false;
      this.isRealized = false;
      this.orderLoadingTypes = 1;
      this.orderLoadingTypesRealized = 1;
      this.pageLoad = -1;
      this.pageLoadRealizeds = -1;
      this.selectedLoadingType = '';
      this.selectedLoadingTypeRealized = '';
    },
    setStatusToShowCombo(status) {
      this.showComboBeneficiaries = status;
    },
    onClickComboBeneficiary() {
      this.refreshRegister();
    },
    initUserId() {
      if (this.$auth.user() && this.$auth.user().beneficiaries) {
        let user = this.$auth.user().beneficiaries[0];
        this.user = {cpf: user.personCPF, userId: user.userId, name: user.personName}
      } else if (this.$auth.user() && this.$auth.user().dependents) {
        let user = this.$auth.user().dependents[0];
        this.user = {cpf: user.personCPF, name: user.personName, userId: user.userId, dependentId: user.dependentId}
      } else {
        this.user.cpf = this.$auth.user() ? this.$auth.user().cpf : null;
      }
      this.beneficiaries = this.$auth.getBeneficiariesAndDependents();
    },
  },
  beforeCreate() {
    this._examEventService = new ExamEventService();
  },
  async mounted() {
    this.initUserId();
    await this.detailExamByPushNotification();
    this.getTotals();
    await this.getExams();
  }
}
</script>

<style>
/*.v-icon.v-icon  {*/
/*  color: var(--v-tertiary) !important;*/
/*  font-size: 15px !important;*/
/*  padding-left: 20px !important;*/
/*}*/

.list-card {
  border-radius: 20px !important;

}

.option-list-card {
  width: 100% !important;
  height: 66px;
}

.plus-icon {
  border-radius: 50% !important;
  height: 60px;
  width: 60px;
  position: fixed !important;
  right: 30px;
  bottom: 30px;
}

.share-icon {
  border-radius: 50% !important;
  height: 45px;
  width: 45px;
  position: fixed !important;
  right: 38px;
  bottom: 110px;
}

.success-card {
  position: absolute;
  bottom: 0;
  left: 7.5%;
  height: 80vh;
  width: 85vw;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  z-index: 10;
}

@media (max-width: 379px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 24%;
    top: -100px;
  }
}

@media (min-width: 380px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 27.5%;
    top: -100px;
  }
}

@media (min-width: 320px) {
  .col-card{
    margin-right: -100px !important;
  }
}

@media (min-width: 420px) {
  .col-card{
    margin-right: -150px !important;
  }
}

@media (max-width: 389px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 13px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 4px !important;
  }
}

@media (min-width: 390px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 16px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 2px !important;
  }
}

.background-glass-effect{
  background: rgba(255, 255, 255, .5);
  mix-blend-mode: normal;
  padding: 10px;
}
</style>
