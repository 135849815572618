/* eslint-disable */
import HttpService from '../HttpService'

export default class ExamTypeService {
    constructor() {
        this._httpService = new HttpService(`healthcare`);
    }

    async getAll(){
        return await this._httpService.get('/exam_type');
    };

}
