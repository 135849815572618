<template>
  <v-dialog v-model="accTerm" width="90%" transition="dialog-bottom-transition">
    <v-card style="height: 80vh" class="card-text">
      <p style="font-weight: 700" class="mt-2 generic-title text-center">Importante</p>
      <p class="generic-text text-center mb-8">
        Ao enviar resultados de exame, você concorda que é de sua responsabilidade o compartilhamento dessas informações pessoais.
      </p>

      <p class="generic-text text-center mb-10">
        Caso o exame não tenha anexos, você pode compartilhar pelo link do laboratório.
      </p>

      <p class="generic-text text-center mb-10">
        O link pode demorar para ficar disponível e após fica ativo por 3 horas.
      </p>

      <generic-btn
        :btn-props="{ block: true, large: true }"
        :on-click="btnAction"
      >
        Ok
      </generic-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'shareWarn',
  props: {
    accTerm: {
      type: Boolean,
      required: true
    },
    btnAction: {
      type: Function,
      required: true
    },
  }
}
</script>

<style scoped>

.card-text {
  backdrop-filter: blur(10px);
  border-radius: 40px;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 2%;
  background: rgba(255, 255, 255, .6);
  width: 90vw;
  height: 80vh;
}
</style>