/* eslint-disable */
import HttpService from '../HttpService'


export default class ExamEventService {
    constructor() {
        this._httpService = new HttpService(`healthcare`);
    }

    async create(data) {
        return await this._httpService.post('/exam_event', data);
    }

    async update(id, data) {
        return await this._httpService.put(`/exam_event/${id}`, data);
    }

    async realizeExam(data) {
        return await this._httpService.post('/exam_event/realize_exam', data);
    }
    async findById(id){
        return await this._httpService.get(`/exam_event/${id}`);
    }

    async countExamTotals(userId, dependentId){
        return await this._httpService.get('/exam_event/get_exam_event_totalizers', { userId, dependentId });
    }

    async findAllClosedExams(){
        return await this._httpService.get('/exam_event/getClosedExamEvents');
    }

    async findAllOpenedExams(){
        return await this._httpService.get('/exam_event/getOpenedExamEvents');
    }

    async findLaboratoriesHistory(userId, dependentId){
        return await this._httpService.get('/exam_event/find_laboratories_history', { userId, dependentId });
    }

    async FindAllByUserId(){
        return await this._httpService.get('/exam_event/find_all_by_user');
    }

    async FindAllByUserAndFilter(params, userId, dependentId){
        params.userId = userId;
        params.dependentId = dependentId;
        return await this._httpService.get('/exam_event/find_all_by_user_and_filter', params);
    }

    async SharingExamAttachment(examsId){
        return await this._httpService.get(`/exam_event/get_attachment_sharing?examEventIds=${examsId}`);
    }

    async SharingExamLaboratory(examsId){
        return await this._httpService.get(`/exam_event/get_laboratory_sharing?examEventId=${examsId}`);
    }
}
